if (document.querySelector(".testbtn")) {
    const testbtn = document.querySelector(".testbtn");
    console.log("JS UCITAN");
    testbtn.addEventListener("click", () => {
        const sliderFirstPos = document.querySelector(".slider-1");
        const sliderSecondPos = document.querySelector(".slider-2");
        const sliderMainPos = document.querySelector(".slider-3");
        const firstDot = document.querySelector(".first-dot");

        sliderMainPos.classList.add("slidingThird");
        sliderMainPos.classList.add("sliderFirstPos");
        sliderMainPos.classList.remove("sliderMainPos");

        sliderSecondPos.classList.add("slidingSecond");
        sliderSecondPos.classList.add("sliderMainPos");
        sliderSecondPos.classList.remove("sliderSecondPos");

        sliderFirstPos.classList.add("slidingFirst");
        sliderFirstPos.classList.add("sliderSecondPos");
        sliderSecondPos.classList.remove("sliderFirstPos");

        if (sliderSecondPos.classList.contains("sliderMainPos")) {
            firstDot.nextElementSibling.classList.add("dot-active");
        }
    });
}
