if (!window.location.href.includes("portal")) {
    const jobDetailFormJoblift = document.querySelector(
        "#job-application-form"
    );
    const contactFormJoblift = document.querySelector(
        "#template-contactform-flyout"
    );
    function checkform(form) {
        // get all the inputs within the submitted form
        var inputs = form.getElementsByTagName("input");
        for (var i = 0; i < inputs.length; i++) {
            // only validate the inputs that have the required attribute
            if (inputs[i].hasAttribute("required")) {
                if (inputs[i].value == "") {
                    // found an empty field that is required
                    return false;
                }
            }
        }
        return true;
    }

    const runJobLiftFunction = () => {
        if (
            checkform(contactFormJoblift) === true ||
            checkform(jobDetailFormJoblift) === true
        ) {
            document
                .querySelector(
                    'script[src="https://assets.joblift.com/tr/de.js"]'
                )
                .remove();
            const newScript = document.createElement("script");
            newScript.innerHTML = `(function (j,o,b,l,i,f,t) {
            j.jlq = [['init', { cid: '705', tid_param: 'jl_tid', inital_page_view: false }]];
            l=o.createElement('script');l.async=true;l.src=b;i=o.getElementsByTagName('head')[0];i.appendChild(l);
        })(window, document, 'https://assets.joblift.com/tr/de.js');
        window.jlq.push(["application_finished"]);
        console.log("JobLift function should run")`;

            document.head.append(newScript);
        }
    };

    if (
        document.querySelector(
            'script[src="https://assets.joblift.com/tr/de.js"]'
        )
    ) {
        console.log("Joblift was accepted");

        const submitFormFlyoutBtn = document.querySelector(
            "#contact_form__submit"
        );
        const submitFormDetailPageBtn = document.querySelector(
            "#job_application_form__submit"
        );
        submitFormFlyoutBtn.addEventListener("click", () => {
            runJobLiftFunction();
        });
        if (document.querySelector("#job_application_form__submit")) {
            submitFormDetailPageBtn.addEventListener("click", () => {
                runJobLiftFunction();
            });
        }
    }
}

if (document.querySelector(".genericModalTracking")) {
    const genericModalForm = document.querySelector(".genericModalTracking");
    const submitModalFormBtn =
        genericModalForm.querySelector(".gtm-send-button");
    submitModalFormBtn.addEventListener("click", () => {
        setTimeout(() => {
            const rowsWithErrors = document.querySelectorAll(".row.error");
            if (rowsWithErrors.length > 0) {
                console.log("There are rows with errors.");
            } else {
                console.log("There are no rows with errors.");
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    event: "formSubmission",
                    event_params: {
                        formID: form.id,
                        formType: "Custom Form",
                        formPath: `${window.location.pathname}`,
                    },
                });
            }
        }, 300);
    });
}
