// window.addEventListener('DOMContentLoaded', (event) => {
    let isFlyOutToggled = false;
    window.addEventListener("DOMContentLoaded", () => {
        $(".contact-fly-out-toggler").click(() => {
            $(".contact-fly-out").toggleClass("transform");
            $("#content").toggleClass('blur-effect');
            $("#footer").toggleClass('blur-effect');
            $('.home-header').toggleClass('blur-effect');
            isFlyOutToggled = !isFlyOutToggled;
        });
        $(".contact-fly-out-cross-toggler").click(() => {
            $(".contact-fly-out").toggleClass("transform");
            $("#content").toggleClass('blur-effect');
            $("#footer").toggleClass('blur-effect');
            $('.home-header').toggleClass('blur-effect');
            isFlyOutToggled = !isFlyOutToggled;
            if (window.innerWidth < 1250 || window.innerHeight < 750) {
                document.body.style.overflow = "unset";
                document.querySelector(".contact-fly-out").style.removeProperty("overflow");
            }
        });
        $(".contact-fly-out-toggler-mobile").click(() => {
            $(".contact-fly-out").toggleClass("transform");
            $("#content").toggleClass('blur-effect');
            $("#footer").toggleClass('blur-effect');
            $('.home-header').toggleClass('blur-effect');
            isFlyOutToggled = !isFlyOutToggled;
            if (isFlyOutToggled) {
                document.body.style.overflow = "hidden";
                document.querySelector(".contact-fly-out").style.overflow =
                    "scroll";
            } else {
                document.body.style.overflow = "unset";
                document.querySelector(".contact-fly-out").style.removeProperty("overflow");
            }
        });
    });
    window.addEventListener("resize", () => {
        if (window.innerWidth >= 1250 && window.innerHeight >= 750) {
            if (
                document.body.style.overflow === "hidden" &&
                document.querySelector(".contact-fly-out").style.overflow ===
                "scroll"
            ) {
                document.body.style.overflow = "unset";
                document.querySelector(".contact-fly-out").style.removeProperty("overflow");
            }
        } else {
            if (isFlyOutToggled) {
                document.body.style.overflow = "hidden";
                document.querySelector(".contact-fly-out").style.overflow =
                    "scroll";
            }
        }
    });

// });
