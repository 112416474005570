// Znaci na load da se salje subscribed-user-status
// Dok se ne prihvati mejl subscribed ide na false
// Iako se ne prihvati mejl, staviti unsub formu u drodown
// Dodati locale na rute
// Ako je na dva browsera popraviti onaj switch posto je narandaast
// Ako je user exist false obrisati user id

// ALL VARIABLES
if (!window.location.href.includes("portal")) {
    // if(document.querySelector('.subs-content')){


    console.log("USAO SAM U KOD");

    const switchBtn = document.querySelector("#switch");
    const subsForm = document.querySelector(".subs-form");
    const agreeBtn = document.querySelector(".agree-button");
    const deleteAgree = document.querySelector(".delete-agree");
    const deleteDisagree = document.querySelector(".delete-disagree");
    const subsModalTitle = document.querySelector(".subs-modal-title");
    const jobtitleInput = document.querySelector("#jobtitle");
    const saveLinkBtn = document.querySelector(".save-link-btn");
    const jobsSavedSpan = document.querySelector(".jobs-saved-amount");
    const subscribeBtn = document.querySelector(".subscribe-btn");
    const savedJobError = document.querySelector(".savedjob-error");
    const formContent = document.querySelector(".subs-form");
    const subsButton = document.querySelector(".subs-contact");

    const langAttribute = document.querySelector("html").getAttribute("lang");

    // EMAIL TOGGLE BUTTON FUNCIONALITY

    if (switchBtn) {
        subsButton.addEventListener("click", () => {
            subsForm.classList.toggle("form-opened");

            if (subsForm.classList.contains("agreed")) {
                formContent.classList.add("form-unsubscribe");
                formContent.innerHTML = `<p>Um keine E-Mails mehr von uns zu erhalten, deaktivieren Sie die E-Mail-Benachrichtigungen und bestätigen Sie dies.</p>
            <div class="d-flex unsubscribe-checkbox">
            <span class="form-error-unsub">Please check checkbox</span>
              <input type="checkbox" id="subscription_form_unsubscribe" name="contact_form[unsubscribe]" required="required" value="1">
              <p>Ich möchte keine E-Mail-Benachrichtigungen mehr bekommen.</p>
              </div>
                 <div class="unsubscribe-agree-button">
                  <a>Bestätigen</a>
              </div>`;
                const unsubBtn = document.querySelector(
                    ".unsubscribe-agree-button"
                );
                unsubBtn.addEventListener("click", () => {
                    if (
                        document.getElementById("subscription_form_unsubscribe")
                            .checked
                    ) {
                        let unsubData = {
                            user_id: `${localStorage.getItem("user_id")}`,
                        };
                        console.log(unsubData);
                        fetch(`/${langAttribute}/delete-subsciption-user`, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(unsubData),
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                console.log("Request complete! response:", res);
                            });
                        subsForm.classList.remove("form-opened");
                        localStorage.removeItem("subscribed");
                        localStorage.removeItem("user_id");
                        setTimeout(() => {
                            location.reload();
                        }, 500);
                    } else {
                        document.querySelector(
                            ".form-error-unsub"
                        ).style.display = "block";
                    }
                });
            }
        });
    }

    // SHOW SUBSCRIBE BUTTON ONLY IF USER SEARCHES FOR A JOB

    const topUrl = window.location.search;
    const searched = new URLSearchParams(topUrl);
    const jobtitleParam = searched.get("jobtitle");
    if (document.querySelector(".jobs")) {
        if (jobtitleParam == "") {
            subscribeBtn.style.setProperty("display", "none", "important");
        } else {
            subscribeBtn.style.setProperty("display", "flex", "important");
        }
        if (searched.has("jobtitle") == false) {
            subscribeBtn.style.setProperty("display", "none", "important");
        }
    }

    // SAVING URL TO LOCAL STORAGE

    function saveUrl() {
        let existingUrls = JSON.parse(localStorage.getItem("savedURLs"));
        if (existingUrls == null) existingUrls = [];
        let urlTitle = document.getElementById("jobtitle").value;
        let entryUrl = window.location.href;
        let newUrl = {
            title: urlTitle,
            url: entryUrl,
            date: new Date().toISOString().slice(0, 10),
        };
        localStorage.setItem("url", JSON.stringify(newUrl));

        existingUrls.push(newUrl);
        localStorage.setItem("savedURLs", JSON.stringify(existingUrls));
    }
    // COUNTER FOR STAR ICON
    const savedJobCounter = () => {
        let jobsNumber = Number(jobsSavedSpan.innerText);
        jobsSavedSpan.innerText = jobsNumber + 1;
    };
    const jobCounterDecrease = () => {
        let jobsNumber = Number(jobsSavedSpan.innerText);
        jobsSavedSpan.innerText = jobsNumber - 1;
    };
    // SUCCES MESSAGE AFTER SAVE
    const saveSuccess = () => {
        saveLinkBtn.innerHTML = `Suche gespeichert <i class="icon checkmark-icon icon-16 icon-white"></i>`;
        saveLinkBtn.disabled = "true";
    };
    // CHECKING IF USER ALREADY SUBSCRIBED TO JOB
    const checkIfAlreadySubscribed = () => {
        let urls = localStorage.getItem("savedURLs");
        let currentUrl = window.location.href;
        console.log(urls);
        if (urls == null) {
            saveUrl();
            saveSuccess();
        }
        if (urls) {
            if (!urls.includes(`${currentUrl}`)) {
                saveUrl();
                saveSuccess();
                // if (localStorage.getItem("subscribed") == "true" ||) {
                let newSearchUpdate = {
                    id: `${localStorage.getItem("user_id")}`,
                    urls: [window.location.href],
                    subsciptionEmailId: Number(
                        document
                            .querySelector("body")
                            .getAttribute("data-job-subsciption-email-id")
                    ),
                };
                console.log(newSearchUpdate);
                fetch(`/${langAttribute}/update-job-subscriptions`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(newSearchUpdate),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        console.log("Request complete! response:", res);
                    });
                // }
            } else {
                savedJobError.style.display = "block";
                jobCounterDecrease();
            }
        }
    };
    if (saveLinkBtn) {
        saveLinkBtn.addEventListener("click", () => {
            checkIfAlreadySubscribed();
            savedJobCounter();
        });
    }
    // SET MESSAGE IF USER HAS NO SAVED SEARCHES
    const searchContainer = document.querySelector(".search-container");
    const emptyMessage = () => {
        const emptyMessageDiv = document.createElement("div");
        emptyMessageDiv.innerHTML = `<div class="empty-search d-flex flex-column justify-content-center align-items-center">
    <div>
      <i class="icon bell-icon icon-white"></i>
      </div>
      <h1>Sie haben noch keine gespeicherten Suchen.</h1>
      <p>Führen Sie einfach eine Jobsuche aus und speichern Sie diese Suche für später.</p>
      <a href="/de/jobs?page=1">Jetzt Jobs suchen</a>
     </div>`;
        searchContainer.appendChild(emptyMessageDiv);
    };

    const renderDataFromBackend = async () => {
        const requestAdress = `/${langAttribute}/user-subscriptions`;
        let data = {
            id: localStorage.getItem("user_id"),
        };
        const res = await fetch(requestAdress, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        });
        const dataResult = await res.json();
        console.log(dataResult);
        return dataResult;
    };
    const modalTitleDelete = document.querySelector(".modal-delete-title");
    window.addEventListener("load", async () => {
        if (window.location.href.indexOf("jobs-search-subscribe") > -1) {
            if (!(await renderDataFromBackend()) === false) {
                document
                    .querySelectorAll(".subs-item")
                    .forEach((elem) => elem.remove());
                const dataFromBackend = await renderDataFromBackend();
                let dataObj = [];
                dataFromBackend.forEach((data) => {
                    const savedUrl = new URL(data.url);
                    const searchParam = savedUrl.searchParams;
                    const jobTitleParam = searchParam.get("jobtitle");
                    let itemtoappend = {
                        date: data.date,
                        title: jobTitleParam,
                        url: data.url,
                    };
                    dataObj.push(itemtoappend);
                    console.log(dataObj);
                    localStorage.setItem("savedURLs", JSON.stringify(dataObj));
                });
                // if (dataObj.length > 0) {
                //     document.querySelector(".empty-search").remove();
                // }
                renderFunc(dataObj);
                const deleteButton = document.querySelectorAll(".delete");

                deleteButton.forEach((elem) => {
                    elem.addEventListener("click", () => {
                        let deleteTitle =
                            elem.parentElement.parentElement.querySelector(
                                ".item-url"
                            ).innerText;
                        modalTitleDelete.innerText = deleteTitle;

                        let allSavedSearches =
                            localStorage.getItem("savedURLs");
                        allSavedSearches = JSON.parse(allSavedSearches);
                        let dataIDAttribute =
                            elem.parentElement.parentElement.getAttribute(
                                "data-id"
                            );
                        deleteAgree.setAttribute(
                            "data-delete",
                            `${dataIDAttribute}`
                        );
                        deleteAgree.addEventListener("click", () => {
                            let urlToDelete =
                                deleteAgree.getAttribute("data-delete");
                            let removeIndex = dataObj
                                .map(function (item) {
                                    return item.url;
                                })
                                .indexOf(`${urlToDelete}`);
                            dataObj.splice(removeIndex, 1);
                            allSavedSearches.splice(removeIndex, 1);
                            console.log(allSavedSearches);
                            localStorage.setItem(
                                "savedURLs",
                                JSON.stringify(allSavedSearches)
                            );
                            console.log(dataObj);
                            let domElement = document.querySelector(
                                `[data-id="${urlToDelete}"]`
                            );
                            domElement.remove();
                            jobCounterDecrease();
                            urlToDelete = urlToDelete.replace(
                                "&subscription=1",
                                ""
                            );
                            let removedData = {
                                user_id: `${localStorage.getItem("user_id")}`,
                                url: urlToDelete,
                            };
                            console.log(removedData);
                            fetch(
                                `/${langAttribute}/unsubscribe-pre-filtered-job-search`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(removedData),
                                }
                            )
                                .then((res) => res.json())
                                .then((res) => {
                                    console.log(
                                        "Request complete! response:",
                                        res
                                    );
                                });
                            console.log(dataObj);

                            if (dataObj.length == 0) {
                                emptyMessage();
                            }
                        });
                    });
                });
            }
        }
    });

    // CHECK IF USER HAS SEARCHES
    if (searchContainer) {
        setTimeout(() => {
            let testingUrl = localStorage.getItem("savedURLs");
            testingUrl = JSON.parse(testingUrl);
            if (urls) {
                if (testingUrl.length == 0) {
                    emptyMessage();
                }

                jobsSavedSpan.innerText = `${testingUrl.length}`;
            }
            if (!testingUrl) {
                emptyMessage();
            }
        }, 2300);
        let urls = localStorage.getItem("savedURLs");
        urls = JSON.parse(urls);
        // if (urls) {
        //         if (urls.length == 0) {
        //             emptyMessage();
        //         }

        //     jobsSavedSpan.innerText = `${urls.length}`;
        // }
        //     if (!urls) {
        //         emptyMessage();
        //     }

        var renderFunc = (data) => {
            console.log("usao sam u func");
            console.log(data);
            data.forEach((elem) => {
                console.log(elem);
                const savedUrl = new URL(elem.url);
                const searchParam = savedUrl.searchParams;

                const jobTitleParam = searchParam.get("jobtitle");

                if (searchParam.get("scopes")) {
                    var scopesParam = `+ ${searchParam.get("scopes")}`;
                } else {
                    var scopesParam = ``;
                }
                if (searchParam.get("contract-types")) {
                    var contractyTypesParam = `+ ${searchParam.get(
                        "contract-types"
                    )}`;
                    console.log(contractyTypesParam);
                } else {
                    var contractyTypesParam = ``;
                }
                if (searchParam.get("professional-groups")) {
                    var professionalGroupParam = `+ ${searchParam.get(
                        "professional-groups"
                    )}`;
                } else {
                    var professionalGroupParam = ``;
                }
                if (searchParam.get("perimeter")) {
                    var perimeterParam = `+ ${searchParam.get("perimeter")} km`;
                } else {
                    var perimeterParam = ``;
                }
                if (searchParam.get("city")) {
                    var cityParam = `+ ${searchParam.get("city")}`;
                } else {
                    var cityParam = ``;
                }

                // Formating Date of saved search
                let dateString = elem.date;
                const date = new Date(dateString);

                const day = date.getDate();
                const month = date.getMonth() + 1; // JavaScript months are zero-based, so we need to add 1 to get the correct month number
                const year = date.getFullYear();

                let formattedDate = `${day}.${month
                    .toString()
                    .padStart(2, "0")}.${year}`;

                const newDiv = document.createElement("div");
                newDiv.innerHTML = `<div class="subs-item mb-4" data-id="${elem.url}">
        <div class="item-title">
           <div>
              <a class="item-url" href=${elem.url}>
              ${elem.title}
              </a>
           </div>
           <div>
               <a href="${elem.url}" class="subs-link">

              <i class="icon right-arrow-icon icon-black icon-20"></i>
               </a>
           </div>
        </div>
        <p class="m-0">${jobTitleParam} ${scopesParam} ${contractyTypesParam} ${professionalGroupParam} ${cityParam} ${perimeterParam}</p>
        <p class="search-date m-0">gespeichert am ${formattedDate} </p>
        <div class="item-delete-checkbox">
           <div class="delete" data-toggle="modal" data-target="#exampleModalCenter">
              <i class="icon trash-icon icon-black icon-16 "></i>
              <span>Löschen</span>
           </div>
        </div>
     </div>`;
                searchContainer.appendChild(newDiv);
            });
        };
        // DISPLAY ALL USER SAVED SEARCHES + FILTERS
        if (urls) {
            renderFunc(urls);
        }
    }
    let urls = localStorage.getItem("savedURLs");
    if (urls) {
        urls = JSON.parse(urls);
        jobsSavedSpan.innerText = `${urls.length}`;
    }

    // Request Handling & form validation

    const sendRequestButton = document.querySelector(".agree-button");
    const urlParametar = new URLSearchParams(window.location.search);
    const jobtitle = urlParametar.get("jobtitle");
    let subscriptionFormName = document.querySelector(
        "#subscription_form_name"
    );
    let subscriptionFormEmail = document.querySelector(
        "#subscription_form_email"
    );
    let subscriptionFormPrivacyPolicy = document.querySelector(
        "#subscription_form_privacyPolicy"
    );
    const subscribeFormValidation = () => {
        const formErrorEmail = document.querySelector(".form-error-email");
        const formErrorName = document.querySelector(".form-error-name");
        const formErrorPrivacy = document.querySelector(".form-error-privacy");
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        formErrorEmail.style.display = "none";
        formErrorName.style.display = "none";
        formErrorPrivacy.style.display = "none";

        if (!emailRegex.test(subscriptionFormEmail.value)) {
            formErrorEmail.style.display = "block";
        }
        if (subscriptionFormName.value.length < 2) {
            formErrorName.style.display = "block";
        }
        if (!subscriptionFormPrivacyPolicy.checked) {
            formErrorPrivacy.style.display = "block";
        }
        if (
            emailRegex.test(subscriptionFormEmail.value) &&
            subscriptionFormName.value.length > 1 &&
            subscriptionFormPrivacyPolicy.checked
        ) {
            subsForm.classList.remove("form-opened");
            subsForm.classList.add("agreed");
            return true;
        } else {
            return false;
        }
    };

    // ARRAY OF ALL SEARCHES

    let urlListArr = [];
    const urlLists = document.querySelectorAll(".subs-item");
    urlLists.forEach((url) => {
        let urlAttribute = url.getAttribute("data-id");
        urlListArr.push(urlAttribute);
    });

    // SEND USER DATA TO DATABASE
    const sendUserDataRequest = async () => {
        const requestAdress = `/${langAttribute}/subscribe-pre-filtered-job-search`;
        let data = {
            email: `${subscriptionFormEmail.value}`,
            name: `${subscriptionFormName.value}`,
            urls: urlListArr,
            subsciptionEmailId: Number(
                document
                    .querySelector("body")
                    .getAttribute("data-job-subsciption-email-id")
            ),
        };
        console.log(data);

        fetch(requestAdress, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((res) => {
                console.log("Response complete!", res);
                localStorage.setItem("user_id", res.user_id);
                localStorage.setItem("subscribed", "false");
                window.location.reload();
            });
    };
    // DELETE SEARCH HANDLER
    const deleteBtns = document.querySelectorAll(".delete");

    const deleteItemHandler = (elem) => {
        elem.addEventListener("click", () => {
            let deleteTitle =
                elem.parentElement.parentElement.querySelector(
                    ".item-url"
                ).innerText;
            modalTitleDelete.innerText = deleteTitle;

            console.log("klikbuo");
            let dataIDAttribute =
                elem.parentElement.parentElement.getAttribute("data-id");
            deleteAgree.setAttribute("data-delete", `${dataIDAttribute}`);
            deleteAgree.addEventListener("click", () => {
                let allSavedSearches = localStorage.getItem("savedURLs");
                let urlToDelete = deleteAgree.getAttribute("data-delete");
                allSavedSearches = JSON.parse(allSavedSearches);
                let removeIndex = allSavedSearches
                    .map(function (item) {
                        return item.url;
                    })
                    .indexOf(`${urlToDelete}`);
                allSavedSearches.splice(removeIndex, 1);
                console.log(allSavedSearches);
                localStorage.setItem(
                    "savedURLs",
                    JSON.stringify(allSavedSearches)
                );
                let domElement = document.querySelector(
                    `[data-id="${urlToDelete}"]`
                );
                domElement.remove();
                jobCounterDecrease();

                let removedData = {
                    user_id: `${localStorage.getItem("user_id")}`,
                    url: urlToDelete,
                };
                console.log(removedData);
                fetch(`/${langAttribute}/unsubscribe-pre-filtered-job-search`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(removedData),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        console.log("Request complete! response:", res);
                    });
                if (allSavedSearches.length == 0) {
                    emptyMessage();
                }
            });
        });
    };
    deleteBtns.forEach((elem) => {
        deleteItemHandler(elem);
    });
    // const reloadAfterSendingData = async () => {
    //     await sendUserDataRequest().then(
    //         window.location.reload()
    //     )
    // }
    // ENABLE FORM ON JOBS SEARCH
    if (searchContainer) {
        sendRequestButton.addEventListener("click", () => {
            if (subscribeFormValidation() === true) {
                sendUserDataRequest();
            }
        });
    }

    const sendRequestOnJobSearch = () => {
        checkIfAlreadySubscribed();
        savedJobCounter();
        let allurlArr = [];
        let allurl = localStorage.getItem("savedURLs");
        allurl = JSON.parse(allurl);
        if (!allurl) return;
        allurl.forEach((elem) => {
            allurlArr.push(elem.url);
        });
        const requestAdress = `/${langAttribute}/subscribe-pre-filtered-job-search`;
        let data = {
            email: `${subscriptionFormEmail.value}`,
            name: `${subscriptionFormName.value}`,
            urls: allurlArr,
            subsciptionEmailId: Number(
                document
                    .querySelector("body")
                    .getAttribute("data-job-subsciption-email-id")
            ),
        };
        fetch(requestAdress, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((res) => {
                console.log("Response complete!", res);
                localStorage.setItem("user_id", res.user_id);
                localStorage.setItem("subscribed", "false");
            });
    };
    if (document.querySelector(".jobs-headers")) {
        sendRequestButton.addEventListener("click", () => {
            if (subscribeFormValidation() === true) {
                sendRequestOnJobSearch();
            }
        });
    }

    if (window.location.href.indexOf("jobs-search-subscribe") > -1) {
        const urlSubs = new URL(window.location.href);

        if (urlSubs.searchParams.has("unsubscribe")) {
            localStorage.removeItem("user_id");
            localStorage.removeItem("subscribed");

            urlSubs.searchParams.delete("unsubscribe");
            console.log(
                "Removed 'unsubscribe' parameter from URL:",
                urlSubs.href
            );
            window.history.replaceState(null, "", urlSubs.href); // update the URL without reloading the page

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    }

    async function fetchSubscribedUserStatus() {
        try {
            const userId = localStorage.getItem("user_id");
            if (!userId) {
                console.log(
                    "user_id not found in localStorage. Aborting fetch request."
                );
                localStorage.setItem("subscribed", false);
                switchBtn.checked = false;
                return;
            }
            const url = `/${langAttribute}/subscribed-user-status`;
            const data = {
                id: userId,
            };
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const responseData = await response.json();
            console.log("Response:", responseData);
            if (responseData.user_exist === false) {
                localStorage.removeItem("user_id");
            }
            if (responseData.user_subscribed === true) {
                localStorage.setItem("subscribed", true);
            } else {
                localStorage.setItem("subscribed", false);
            }

            // ADD AGREED CLASS IF SUBSCRIBED
            if (searchContainer || document.querySelector(".jobs-headers")) {
                if (responseData.user_exist === true) {
                    subsForm.classList.add("agreed");
                    switchBtn.checked = responseData.user_subscribed;
                } else {
                    subsForm.classList.remove("agreed");
                    switchBtn.checked = false;
                }
            }
        } catch (error) {
            console.error(
                "There was a problem with your fetch operation:",
                error
            );
        }
    }
    window.addEventListener("load", async () => {
        if (window.location.href.indexOf("jobs") > -1) {
        fetchSubscribedUserStatus();
            if (!(await renderDataFromBackend()) === false) {
                const dataFromBackend = await renderDataFromBackend();
                let dataObj = [];
                dataFromBackend.forEach((data) => {
                    const savedUrl = new URL(data.url);
                    const searchParam = savedUrl.searchParams;
                    const jobTitleParam = searchParam.get("jobtitle");
                    let itemtoappend = {
                        date: data.date,
                        title: jobTitleParam,
                        url: data.url,
                    };
                    dataObj.push(itemtoappend);
                    console.log(dataObj);
                    localStorage.setItem("savedURLs", JSON.stringify(dataObj));
                });
            }
        }
    });
    if (agreeBtn) {
        agreeBtn.addEventListener("click", () => {
            if (document.querySelector(".checkmark-icon")) {
                document.querySelector(".savedjob-error").style.display =
                    "none";
            }
        });
    }
// }
}
