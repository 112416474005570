
$( window ).resize(function() {
    
    $('.masonry-thumbs').each(function(index) {
        if($(window).width() < 768){
            $(this).removeClass($(this).data('desktopgrid'));
            $(this).addClass('grid-2');
            if($(this).data('big') % 2 == 0){
                $(this).attr('data-big', $(this).data('desktopbig') - 1);
            }
        }else{
            $(this).removeClass('grid-2');
            $(this).addClass($(this).data('desktopgrid'));
            $(this).attr('data-big', $(this).data('desktopbig'));
        }
    });
});

$('.masonry-thumbs').each(function(index) {
    if($(window).width() < 768){
        $(this).removeClass($(this).data('desktopgrid'));
        $(this).addClass('grid-2');
        if($(this).data('big') % 2 == 0) {
            $(this).attr('data-big', $(this).data('desktopbig') - 1);
        }
    }else{
        $(this).removeClass('grid-2');
        $(this).addClass($(this).data('desktopgrid'));
        $(this).attr('data-big', $(this).data('desktopbig'));
    }
});