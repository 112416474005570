// mst search
if (document.querySelector(".meisterbetriebe")) {
    $(document).ready(function () {
        var meisterSearchButton = $("#search-header-btn");
        var meisterSearchInput = $("#search_input-header");
        meisterSearchButton.on("click", sendSubmit);

        meisterSearchInput.on("keypress", function (e) {
            if (e.which == 13) {
                sendSubmit(e);
                console.log(meisterSearchInput);
            }
        });
        function sendSubmit(event) {
            if (meisterSearchInput.val().length > 0) {
                $(event.target)
                    .closest(".jobs-search-container")
                    .find("form")
                    .submit();
            }
        }
        meisterAutocomplete(meisterSearchInput);
    });

    /**autocomplete
     *@input input field for autocomplete functionality
     */
    function meisterAutocomplete(input) {
        //Credentials for Haupia
        const htmlTag = document.documentElement;
        const langAttribute = htmlTag.getAttribute("lang");
        let url = `/${langAttribute}/suche`;
        let limit = 9;
        let headers = undefined;
        console.log(input);

        input
            .autocomplete({
                appendTo: "#search-suggestions-header",
                minLength: 3,
                select: function (event, ui) {
                    window.location.href = ui.item.link;
                },
                source: function (request, response) {
                    console.log(request.term);
                    console.log(response);
                    $.ajax({
                        type: "GET",
                        url: url + "?auto=1&q=" + request.term,
                        dataType: "json",
                        headers: headers,
                        success: function (result) {
                            result = result.slice(0, limit);
                            console.log(result);
                            response(
                                $.map(result, function (item) {
                                    return {
                                        label: item.title,
                                        value: item.title,
                                        link: item.link,
                                    };
                                })
                            );
                        },
                        error: function (error) {
                            console.error(error);
                        },
                    });
                },
            })
            .data("ui-autocomplete")._renderItem = function (ul, item) {
            ul.addClass("search__suggestions");
            return $("<li></li>")
                .data("ui-autocomplete-item", item)
                .append(
                    '<a class="autocomplete-item" href="' +
                        item.link +
                        '">' +
                        item.label +
                        "</a>"
                )
                .appendTo(ul);
        };
    }
    // end mst searh
}
$(document).ready(function () {
    var searchButton = $("#search");
    var searchInput = $("#search_input");
    searchButton.on("click", openInput);

    searchInput.on("keypress", function (e) {
        if (e.which == 13) {
            sendSubmit(e);
        }
    });
    $("#close_search").on("click", function (e) {
        $(e.target)
            .closest(".user-nav-search")
            .removeClass("user-nav-search-open");
        searchButton.off("click");
        searchButton.on("click", openInput);
    });
    function openInput(event) {
        $(event.target)
            .closest(".user-nav-search")
            .addClass("user-nav-search-open");
        searchButton.off("click");
        searchButton.on("click", sendSubmit);
    }
    function sendSubmit(event) {
        if (searchInput.val().length > 0) {
            $(event.target).closest(".user-nav-search").find("form").submit();
        }
    }
    autocomplete(searchInput);
});

/**autocomplete
 *@input input field for autocomplete functionality
 */
function autocomplete(input) {
    //Credentials for Haupia
    const htmlTag = document.documentElement;
    const langAttribute = htmlTag.getAttribute("lang");
    let url = `/${langAttribute}/suche`;
    let limit = 7;
    let headers = undefined;

    input
        .autocomplete({
            appendTo: "#search-suggestions",
            minLength: 3,
            select: function (event, ui) {
                window.location.href = ui.item.link;
            },
            source: function (request, response) {
                console.log(request);
                // console.log(response)
                $.ajax({
                    type: "GET",
                    url: url + "?auto=1&q=" + request.term,
                    dataType: "json",
                    headers: headers,
                    success: function (result) {
                        result = result.slice(0, limit);
                        console.log(result);
                        response(
                            $.map(result, function (item) {
                                return {
                                    label: item.title,
                                    value: item.title,
                                    link: item.link,
                                };
                            })
                        );
                    },
                    error: function (error) {
                        console.error(error);
                    },
                });
            },
        })
        .data("ui-autocomplete")._renderItem = function (ul, item) {
        ul.addClass("search__suggestions");
        return $("<li></li>")
            .data("ui-autocomplete-item", item)
            .append(
                '<a class="autocomplete-item" href="' +
                    item.link +
                    '">' +
                    item.label +
                    "</a>"
            )
            .appendTo(ul);
    };
}

// Job autocomplete
$(document).ready(function () {
    var searchInput = $("#jobtitle");
    var button = $("#jobSearchCloseBtn");

    searchInput.on("keypress", function (e) {
        if (searchInput[0].value.length > 2) {
            autocompleteJob(searchInput);
        }
    });

    button.on("click", function (e) {
        searchInput[0].value = "";
    });
});

/**autocomplete
 *@input input field for autocomplete functionality
 */
function autocompleteJob(input) {
    //Credentials for Haupia
    const htmlTag = document.documentElement;
    const langAttribute = htmlTag.getAttribute("lang");
    let url = `/${langAttribute}/suche`;
    let limit = 7;
    let headers = undefined;

    $("#jobSearchCloseBtn").css("display", "block");

    input
        .autocomplete({
            appendTo: "#search-suggestions-jobs",
            minLength: 3,
            select: function (event, ui) {
                window.location.href = ui.item.link;
            },
            source: function (request, response) {
                $.ajax({
                    type: "GET",
                    url: url + "?auto=1&job-auto=1&q=" + request.term,
                    dataType: "json",
                    headers: headers,
                    success: function (result) {
                        result = result.slice(0, limit);
                        response(
                            $.map(result, function (item) {
                                return {
                                    label: item.title,
                                    value: item.title,
                                    link: item.link,
                                };
                            })
                        );
                    },
                    error: function (error) {
                        console.error(error);
                    },
                });
            },
        })
        .data("ui-autocomplete")._renderItem = function (ul, item) {
        ul.addClass("search__suggestions");
        ul.addClass("top-fix");
        return $("<li></li>")
            .data("ui-autocomplete-item", item)
            .append(
                '<a class="autocomplete-item" href="' +
                    item.link +
                    '">' +
                    item.label +
                    "</a>"
            )
            .appendTo(ul);
    };
}

// City autocomplete
$(document).ready(function () {
    var searchInput = $("#city-select");
    var button = $("#jobSearchCloseBtn");
    var searchHeaderInput = $("#city");

    searchHeaderInput.on("keypress", function (e) {
        if (searchHeaderInput[0].value.length > 0) {
            autocompleteCity(searchHeaderInput);
        }
    });

    searchInput.on("keypress", function (e) {
        if (searchInput[0].value.length > 0) {
            autocompleteCity(searchInput);
        }
    });

    button.on("click", function (e) {
        searchInput[0].value = "";
    });
});

/**autocomplete
 *@input input field for autocomplete functionality
 */
function autocompleteCity(input) {
    //Credentials for Haupia
    const htmlTag = document.documentElement;
    const langAttribute = htmlTag.getAttribute("lang");
    let url = `/${langAttribute}/cities`;
    let limit = 7;
    let headers = undefined;
    let suggestionContainer;
    console.log(input[0].id);
    if (input[0].id == "city-select") {
        suggestionContainer = "#search-suggestion-city";
    } else {
        suggestionContainer = "#search-suggestion-city-header";
    }
    console.log(suggestionContainer);
    $("#jobSearchCloseBtn").css("display", "block");

    input
        .autocomplete({
            appendTo: `${suggestionContainer}`,
            minLength: 2,
            // select: function (event, ui) {
            //     window.location.href = ui.item.link;
            // },
            source: function (request, response) {
                $.ajax({
                    type: "GET",
                    url: url + "?q=" + request.term,
                    dataType: "json",
                    headers: headers,
                    success: function (result) {
                        result = result.slice(0, limit);
                        response(
                            $.map(result, function (item) {
                                return {
                                    city: item.city,
                                };
                            })
                        );
                    },
                    error: function (error) {
                        console.error(error);
                    },
                });
            },
        })
        .data("ui-autocomplete")._renderItem = function (ul, item) {
        ul.addClass("search__suggestions");
        ul.addClass("top-fix");
        if (suggestionContainer == "#search-suggestion-city") {
            ul.addClass("p-0");
        }
        return $(`<li class="p-0"></li>`)
            .data("ui-autocomplete-item", item)
            .append(
                `<a class="city w-100 autocomplete-item" onClick="changeCityValue(event)">${item.city}</a>`
            )
            .appendTo(ul);
    };
}
