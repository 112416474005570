// New Part By Petar

const showFilters = () => {
    const toggleFilters = document.querySelector(".filter");
    $(".filter").toggleClass("toggle-filters");
    const closeIcon = document.querySelector(
        ".jobs-search-body .jobs-search-container .jobs .jobs-headers .filter-link .close-filters-icon"
    );
    const openIcon = document.querySelector(
        ".jobs-search-body .jobs-search-container .jobs .jobs-headers .filter-link .open-filters-icon"
    );
    if (closeIcon && openIcon) {
        if (closeIcon.style.display === "none") {
            $(openIcon).fadeOut(() => {
                $(closeIcon).fadeIn();
            });
        } else {
            $(closeIcon).fadeOut(() => {
                $(openIcon).fadeIn();
            });
        }
    }
    if (toggleFilters) {
        toggleFilters.classList.contains("toggle-filters")
            ? toggleFilters.classList.remove("toggle-filters")
            : toggleFilters.classList.add("toggle-filters");
    }
    if (!$(".filter").hasClass("toggle-filters")) {
        $(".filter").css("display", "none");
        // $(".filter").animate(
        //     { top: "800px", display: "none", opacity: "0" },
        //     800
        // );
    } else {
        $(".filter").css("display", "block");
        // $(".toggle-filters").css({ position: "fixed", top: someValue() });

        $(".toggle-filters").css({ position: someValue()[1] });
        $(".toggle-filters").animate(
            { top: someValue()[0], opacity: "1" },
            800
        );
    }
};

window.addEventListener("DOMContentLoaded", () => {
    const filterLink = document.querySelector(".filter-link");
    if (filterLink) {
        filterLink.addEventListener("click", showFilters);
    }
});
// window.addEventListener("resize", hideFiltersResponsive);

// CHECKBOXES
let checkboxLabels = [];
const newsParentDiv = document.querySelectorAll(
    ".jobs-filter form > div > .toggle-item"
);

newsParentDiv.forEach((el) => {
    checkboxLabels.push(el.id);
    el.children[0].addEventListener("click", () => {
        $(el.children[1]).slideToggle("fast");
    });
});

// DOM elements
const ShowResultsButton = document.querySelector("#show-results-btn");
const jobsShowNumber = document.querySelector("#jobs-pagination");
const FilterContainer = document.querySelector(".filter");
const citySelect = document.querySelector("#city-select");
if (FilterContainer) {
    var Checkboxes = FilterContainer.querySelectorAll('input[type="checkbox"]');
}
const rangeSliderCustom = document.querySelector(".irs-single");

const acitveFilterContainer = document.querySelector(
    ".active-filter-container"
);

// Apply filters on load

window.addEventListener("load", () => {
    let url = window.location.search;
    const urlParams = new URLSearchParams(url);
    if (urlParams.get("jobtitle")) {
        document.querySelector("#jobtitle").value = urlParams.get("jobtitle");
    }
    if (urlParams.get("city")) {
        document.querySelector("#city").value = urlParams.get("city");
        document.querySelector("#city-select").value = urlParams.get("city");
        if (document.querySelector("#city-select")) {
            let citySelect = document
                .querySelector("#city-select")
                .querySelectorAll("option");
            let optionFlag = 0;
            citySelect.forEach((element) => {
                if (element.value == urlParams.get("city")) {
                    optionFlag = 0;
                } else {
                    optionFlag = 1;
                }
            });
            if (optionFlag) {
                // create option using DOM
                const newOption = document.createElement("option");
                const optionText = document.createTextNode(
                    `${urlParams.get("city")}`
                );
                newOption.appendChild(optionText);
                newOption.setAttribute("value", `${urlParams.get("city")}`);
                let jobSelectForm = document.querySelector("#city-select");
                jobSelectForm.appendChild(newOption);
                jobSelectForm.value = urlParams.get("city");
            }
            // document.querySelector("#city-select").children.forEach(element => {
            //     console.log(element.value);
            // })
        }
    }
    if (urlParams.get("perimeter")) {
        document.querySelector(".irs-handle").style.left = `${urlParams.get(
            "perimeter"
        )}%`;
        document.querySelector(".irs-bar").style.width = `${
            parseInt(urlParams.get("perimeter")) + 2
        }%`;
        document.querySelector(".irs-single").style.left = `${urlParams.get(
            "perimeter"
        )}%`;
        document.querySelector(".irs-single").innerText = `${urlParams.get(
            "perimeter"
        )}`;
    }

    // First checkbox list
    if (urlParams.get("professional-groups")) {
        if (document.querySelector("#professional-groups")) {
            let filterChildren = document
                .querySelector("#professional-groups")
                .querySelectorAll("label");
            let acitveFiltersFromParams = urlParams.get("professional-groups");
            acitveFiltersFromParams = acitveFiltersFromParams.split("_");

            acitveFiltersFromParams.forEach((element) => {
                filterChildren.forEach((singleLabel) => {
                    if (singleLabel.innerText == element) {
                        singleLabel.previousElementSibling.checked = true;
                        showFilterActive(singleLabel.innerText, "check");
                    }
                });
            });
        }
    }

    // Second checkbox list
    if (urlParams.get("contract-types")) {
        if (document.querySelector("#contract-types")) {
            let filterChildren = document
                .querySelector("#contract-types")
                .querySelectorAll("label");
            let acitveFiltersFromParams = urlParams.get("contract-types");
            acitveFiltersFromParams = acitveFiltersFromParams.split("_");

            acitveFiltersFromParams.forEach((element) => {
                filterChildren.forEach((singleLabel) => {
                    if (singleLabel.innerText == element) {
                        singleLabel.previousElementSibling.checked = true;
                        showFilterActive(singleLabel.innerText, "check");
                    }
                });
            });
        }
    }

    // Third checkbox list
    if (urlParams.get("scopes")) {
        if (document.querySelector("#scopes")) {
            let filterChildren = document
                .querySelector("#scopes")
                .querySelectorAll("label");
            let acitveFiltersFromParams = urlParams.get("scopes");
            acitveFiltersFromParams = acitveFiltersFromParams.split("_");

            acitveFiltersFromParams.forEach((element) => {
                filterChildren.forEach((singleLabel) => {
                    if (singleLabel.innerText == element) {
                        singleLabel.previousElementSibling.checked = true;
                        showFilterActive(singleLabel.innerText, "check");
                    }
                });
            });
        }
    }
    if (urlParams.get("branche")) {
        if (document.querySelector("#branche")) {
            let filterChildren = document
                .querySelector("#branche")
                .querySelectorAll("label");
            let acitveFiltersFromParams = urlParams.get("branche");
            acitveFiltersFromParams = acitveFiltersFromParams.split("_");

            acitveFiltersFromParams.forEach((element) => {
                filterChildren.forEach((singleLabel) => {
                    if (singleLabel.innerText == element) {
                        singleLabel.previousElementSibling.checked = true;
                        showFilterActive(singleLabel.innerText, "check");
                    }
                });
            });
        }
    }
    // Filter only used on Experts page
    if (urlParams.get("fachbereiche")) {
        if (document.querySelector("#fachbereiche")) {
            let filterChildren = document
                .querySelector("#fachbereiche")
                .querySelectorAll("label");
            let acitveFiltersFromParams = urlParams.get("fachbereiche");
            acitveFiltersFromParams = acitveFiltersFromParams.split("_");

            acitveFiltersFromParams.forEach((element) => {
                filterChildren.forEach((singleLabel) => {
                    if (singleLabel.innerText == element) {
                        singleLabel.previousElementSibling.checked = true;
                        showFilterActive(singleLabel.innerText, "check");
                    }
                });
            });
        }
    }
    UpdateParams();
});

// Main URL
var url = new URL(`${location.protocol}//${location.host}${location.pathname}`);
console.log(url);

// Show results button
const ShowResults = () => {
    window.location.href = url;
};

// Update jobsPagination after select value is changed
const updateJobsNumber = (e) => {
    url.searchParams.append("job-number", e.target.value);
};

// Update distance rairs-barnge params
const updateDistanceRange = () => {
    if (rangeSliderCustom) {
        let value = rangeSliderCustom.innerText;
        url.searchParams.delete("perimeter");
        if (value > 0) {
            url.searchParams.append("perimeter", value);
            showFilterActive(rangeSliderCustom.innerText, "range");

            // if (acitveFilterContainer) {
            //     if (acitveFilterContainer.querySelector(".city")) {
            //         acitveFilterContainer.querySelector(".city").remove();
            //         document.querySelector("#city").value = "";
            //         document.querySelector("#city-select").value = "default";
            //     }
            // }
        }
    }
};

// Add params on window load
window.addEventListener("load", () => {
    url.searchParams.delete("job-number");
    if (jobsShowNumber) {
        url.searchParams.append("job-number", jobsShowNumber.value);
    }
    updateDistanceRange();
});

// Add params in URL on dropdown select
if (jobsShowNumber) {
    jobsShowNumber.addEventListener("change", (e) => {
        console.log(e.target.value);
        const jobNumber = jobsShowNumber.value;
        const baseUrl = window.location.href.split("?")[0];
        const searchArray = window.location.search.split("&");
        searchArray.shift();
        const rest = searchArray.join("&");
        const newUrl = `${baseUrl}?job-number=${jobNumber}&${rest}`;
        window.location.href = newUrl;
        updateLsOnClick();
    });
}

// Upadate result number
const updateResultButton = async () => {
    if (ShowResultsButton) {
        const response = await fetch(`${url}&job-count=1`);
        const data = await response.json();
        if (data) {
            var jobNumber = data.total;
            // console.log(data);
        }
        ShowResultsButton.innerHTML = `
                 ${jobNumber} ${data.title}
                 <i class="icon icon-white icon-16 right-arrow-icon"></i>
               `;
    }
};

// Update range slider on click
window.addEventListener("load", () => {
    if (document.querySelector(".irs-bar")) {
        document.querySelector(".irs-bar").style["pointer-events"] = "none";
    }

    if (document.querySelector(".irs-line")) {
        let rangeSliderInput = document.querySelector(".irs-line");
        rangeSliderInput.addEventListener("mousedown", () => {
            setTimeout(() => {
                updateDistanceRange();
                UpdateParams();
            }, 500);
        });
    }
});

// Update params after checkbox value is changed
const UpdateParams = () => {
    url = new URL(`${location.protocol}//${location.host}${location.pathname}`);
    if (jobsShowNumber) {
        url.searchParams.append("job-number", jobsShowNumber.value);
    } else {
        url.searchParams.append("job-number", "10");
    }

    if (FilterContainer) {
        const filterMenus = FilterContainer.querySelectorAll(".toggle-item");
        if (acitveFilterContainer) {
            acitveFilterContainer.innerHTML = ``;
            updateCity();
            updateDistanceRange();
            UpdateJobTitle();
            if (filterMenus) {
                filterMenus.forEach((singleFilterSection, index) => {
                    const checkBoxesSingleMenu =
                        singleFilterSection.querySelectorAll(
                            'input[type="checkbox"]'
                        );
                    var urlParams = "";
                    checkBoxesSingleMenu.forEach((element) => {
                        if (element.checked) {
                            showFilterActive(
                                element.nextElementSibling.innerText,
                                "check"
                            );
                            urlParams +=
                                "_" + element.nextElementSibling.innerText;
                        }
                    });
                    if (document.querySelector("#contract-types")) {
                        if (urlParams !== "") {
                            // console.log("test");
                            urlParams = urlParams.substring(1);
                            let urlTitle;
                            if (index === 0) {
                                urlTitle = "professional-groups";
                            } else if (index === 1) {
                                urlTitle = "contract-types";
                            } else if (index === 2) {
                                urlTitle = "scopes";
                            } else if (index === 3) {
                                urlTitle = "fachbereiche";
                            } else if (index === 4) {
                                urlTitle = "branche";
                            }
                            url.searchParams.append(urlTitle, urlParams);
                            // console.log("obj");
                            // console.log(index);
                            // console.log(urlTitle, urlParams);
                        }
                    }

                    // Filters for all pages except Experts
                    if (window.location.href.indexOf("cademify") != -1) {
                        if (urlParams !== "") {
                            // console.log("test");
                            urlParams = urlParams.substring(1);
                            let urlTitle;
                            if (index === 0) {
                                urlTitle = "professional-groups";
                            } else if (index === 1) {
                                urlTitle = "scopes";
                            } else if (index === 2) {
                                urlTitle = "fachbereiche";
                            } else if (index === 3) {
                                urlTitle = "branche";
                            }
                            url.searchParams.append(urlTitle, urlParams);
                            // console.log("obj");
                            // console.log(index);
                            // console.log(urlTitle, urlParams);
                        }
                        // console.log(fachbereiche);
                    }
                    if (
                        window.location.href.indexOf("industrieservice") != -1
                    ) {
                        if (urlParams !== "") {
                            // console.log("test");
                            urlParams = urlParams.substring(1);
                            let urlTitle;
                            if (index === 0) {
                                urlTitle = "professional-groups";
                            } else if (index === 1) {
                                urlTitle = "scopes";
                            } else if (index === 2) {
                                urlTitle = "fachbereiche";
                            } else if (index === 3) {
                                urlTitle = "branche";
                            }
                            url.searchParams.append(urlTitle, urlParams);
                            // console.log("obj");
                            // console.log(index);
                            // console.log(urlTitle, urlParams);
                        }
                    }
                });
                console.log(url);
                // Select all active filters
                const activeFilters = acitveFilterContainer.querySelectorAll(
                    ".active-filter-card"
                );

                if (activeFilters) {
                    activeFilters.forEach((element) => {
                        element.addEventListener("click", (e) => {
                            deleteFilterActive(e);
                        });
                    });
                }
            }
        }
        updateResultButton();
        resultBtnChangeColor();
    }
};

// Reset range Slider
const resetRangeSlider = () => {
    document.querySelector(".irs-handle").style.left = `0%`;
    document.querySelector(".irs-bar").style.width = `2.42%`;
    document.querySelector(".irs-single").style.left = `0%`;
    document.querySelector(".irs-single").innerText = `0`;
};

// Delete active filter card
const deleteFilterActive = (event) => {
    const acitveFilterContainer = document.querySelector(
        ".active-filter-container"
    );
    if (acitveFilterContainer) {
        // Uncheck checkbox after deleting active filter
        const uncheckBoxOnDelete = (title) => {
            const filterLabels = FilterContainer.querySelectorAll("label");
            filterLabels.forEach((element) => {
                if (element.innerText === title) {
                    element.previousElementSibling.checked = false;
                }
            });
        };
        if (event.target.tagName.toLowerCase() === "p") {
            if (event.target.parentElement.classList.contains("check")) {
                var filterTitle = event.target.innerText;
                uncheckBoxOnDelete(filterTitle);
                acitveFilterContainer.removeChild(event.target.parentElement);
            } else if (event.target.parentElement.classList.contains("range")) {
                if (document.querySelector(".irs-handle")) {
                    resetRangeSlider();
                    UpdateParams();
                }
                acitveFilterContainer.removeChild(event.target.parentElement);
            } else if (event.target.parentElement.classList.contains("city")) {
                console.log("Gornji Uslov", event.target.parentElement);
                if (citySelect) {
                    citySelect.value = "";
                    if (document.querySelector("#city")) {
                        document.querySelector("#city").value = "";
                    }
                    resetRangeSlider();
                    UpdateParams();
                    acitveFilterContainer.removeChild(
                        event.target.parentElement
                    );
                    acitveFilterContainer.removeChild(
                        acitveFilterContainer.querySelector(
                            ".active-filter-card.range"
                        )
                    );
                }
            } else if (event.target.parentElement.classList.contains("title")) {
                if (document.querySelector("#jobtitle")) {
                    document.querySelector("#jobtitle").value = "";
                    UpdateParams();
                }
                acitveFilterContainer.removeChild(event.target.parentElement);
            }
        } else {
            if (event.target.classList.contains("check")) {
                var filterTitle = event.target.innerText;
                uncheckBoxOnDelete(filterTitle);
                acitveFilterContainer.removeChild(event.target);
            } else if (event.target.classList.contains("range")) {
                if (document.querySelector(".irs-handle")) {
                    document.querySelector(".irs-handle").style.left = `0%`;
                    document.querySelector(".irs-bar").style.width = `2.42%`;
                    document.querySelector(".irs-single").style.left = `0%`;
                    document.querySelector(".irs-single").innerText = `0`;
                    UpdateParams();
                }
                acitveFilterContainer.removeChild(event.target);
            } else if (event.target.classList.contains("city")) {
                if (citySelect) {
                    citySelect.value = "";
                    if (document.querySelector("#city")) {
                        document.querySelector("#city").value = "";
                    }
                    console.log("Donji Uslov", event.target);
                    UpdateParams();
                    acitveFilterContainer.removeChild(event.target);
                }
            } else if (event.target.classList.contains("title")) {
                if (document.querySelector("#jobtitle")) {
                    document.querySelector("#jobtitle").value = "";
                    UpdateParams();
                }
                acitveFilterContainer.removeChild(event.target);
            }
        }
        UpdateParams();
    }
};

// Update city parametar
const updateCity = () => {
    if (citySelect) {
        url.searchParams.delete("city");

        if (citySelect.value !== "default" && citySelect.value !== "") {
            url.searchParams.append("city", citySelect.value);
            let jobSelectForm = document.querySelector("#city-select");
            let activeFilterTitle = `${document
                .querySelector("#city")
                .getAttribute("data-trans")} : ${jobSelectForm.value}`;
            showFilterActive(activeFilterTitle, "city");
            if (acitveFilterContainer) {
                // if (acitveFilterContainer.querySelector(".range")) {
                //     // resetRangeSlider();
                //     acitveFilterContainer.querySelector(".range").remove();
                // }
            }
        }
    }
};

// Show acitve filter card
const showFilterActive = (name, type) => {
    const acitveFilterContainer = document.querySelector(
        ".active-filter-container"
    );
    if (acitveFilterContainer) {
        if (type == "check") {
            let acitveFilterCard = document.createElement("div");
            acitveFilterCard.classList.add("active-filter-card");
            acitveFilterCard.classList.add(`${type}`);
            acitveFilterCard.innerHTML = `<p>${name}</p>`;
            acitveFilterContainer.appendChild(acitveFilterCard);
        } else if (type == "city") {
            if (acitveFilterContainer.querySelector(".city")) {
                acitveFilterContainer.querySelector(".city").remove();
            }
            let acitveFilterCard = document.createElement("div");
            acitveFilterCard.classList.add("active-filter-card");
            acitveFilterCard.classList.add(`${type}`);
            acitveFilterCard.innerHTML = `<p>${name}</p>`;
            acitveFilterContainer.appendChild(acitveFilterCard);
        } else if (type == "range") {
            if (acitveFilterContainer.querySelector(".range")) {
                acitveFilterContainer.querySelector(".range").remove();
            }
            let acitveFilterCard = document.createElement("div");
            acitveFilterCard.classList.add("active-filter-card");
            acitveFilterCard.classList.add(`${type}`);
            acitveFilterCard.innerHTML = `<p>${document
                .querySelector("#filter-range-label")
                .getAttribute("data-trans")} : ${name}</p>`;
            acitveFilterContainer.appendChild(acitveFilterCard);
        } else if (type == "title") {
            if (acitveFilterContainer.querySelector(".title")) {
                acitveFilterContainer.querySelector(".title").remove();
            }
            let acitveFilterCard = document.createElement("div");
            acitveFilterCard.classList.add("active-filter-card");
            acitveFilterCard.classList.add(`${type}`);
            acitveFilterCard.innerHTML = `<p>${document
                .querySelector("#jobtitle")
                .getAttribute("data-trans")} : ${name}</p>`;
            acitveFilterContainer.appendChild(acitveFilterCard);
        }
    }
};

// Event listeners
if (citySelect) {
    citySelect.addEventListener("change", updateCity);
}
if (ShowResultsButton) ShowResultsButton.addEventListener("click", ShowResults);
if (jobsShowNumber)
    jobsShowNumber.addEventListener("change", (e) => {
        updateJobsNumber(e);
    });
if (Checkboxes) {
    Checkboxes.forEach((singleCheckbox) => {
        singleCheckbox.addEventListener("click", UpdateParams);
    });
}
if (rangeSliderCustom) {
    document.querySelector(".irs-handle").addEventListener("mousedown", (e) => {
        const removeListeners = () => {
            document.body.style.cursor = "default";
            document.removeEventListener("mousemove", updateDistanceRange);
            document.removeEventListener("mouseup", removeListeners);
            updateDistanceRange();
            UpdateParams();
        };
        document.body.style.cursor = "pointer";
        document.addEventListener("mousemove", updateDistanceRange);
        document.addEventListener("mouseup", removeListeners);
    });
}

// Job peremiter
if (document.querySelector("#city") && document.querySelector("#city-select")) {
    let jobFormPerimeter = document.querySelector("#city");
    let jobSelectForm = document.querySelector("#city-select");

    // Header job Perimetar
    jobFormPerimeter.addEventListener("change", () => {
        jobSelectForm.value = jobFormPerimeter.value;
        if (jobSelectForm.value == "") {
            // create option using DOM
            const newOption = document.createElement("option");
            const optionText = document.createTextNode(
                `${jobFormPerimeter.value}`
            );
            newOption.appendChild(optionText);
            newOption.setAttribute("value", `${jobFormPerimeter.value}`);
            jobSelectForm.appendChild(newOption);
            jobSelectForm.value = jobFormPerimeter.value;
        }
        updateCity();
        UpdateParams();
    });
    // Select job city
    jobSelectForm.addEventListener("change", () => {
        if (jobSelectForm.value !== "default") {
            jobFormPerimeter.value = jobSelectForm.value;
        } else {
            // jobFormPerimeter.value = "";
        }
        updateCity();
        UpdateParams();
    });
}

window.addEventListener("load", () => {
    let jobFormPerimeter = document.querySelector("#city");
    let jobSelectForm = document.querySelector("#city-select");
    if (jobSelectForm) {
        if (jobSelectForm.value == "" && jobSelectForm) {
            // create option using DOM
            jobSelectForm.value = jobFormPerimeter.value;
        }
    }
    updateCity();
    UpdateParams();
});

// Job title header params

window.addEventListener("load", () => {
    if (document.querySelector("#jobtitle")) {
        let jobTitleInput = document.querySelector("#jobtitle");
        jobTitleInput.addEventListener("change", () => {
            UpdateJobTitle();
            UpdateParams();
        });
    }
});

// Update job title param
const UpdateJobTitle = () => {
    if (document.querySelector("#jobtitle")) {
        let jobTitleInput = document.querySelector("#jobtitle");
        if (jobTitleInput.value !== "jobtitle") {
            if (jobTitleInput.value === "") {
                url.searchParams.delete("jobtitle");
            } else {
                url.searchParams.delete("jobtitle");
                url.searchParams.append("jobtitle", jobTitleInput.value);
            }
            if (jobTitleInput.value !== "") {
                showFilterActive(jobTitleInput.value, "title");
            }
        }
    }
};

// =================================== //
//       Auto complete city            //
// =================================== //
const cityInput = document.querySelector("#city-select");
const citySuggestion = document.querySelector("#search-suggestion-city-header");
const cityInputField = document.querySelector("#city");

const changeCityValue = (event) => {
    setTimeout(() => {
        if (cityInput) {
            console.log(event.target.innerHTML);
            console.log("cityInput: ", cityInput.value);
            cityInput.value = event.target.innerHTML;
            UpdateParams();
        }
        if (cityInputField) {
            console.log(event.target.innerHTML);
            console.log("cityInputField: ", cityInputField.value);
            cityInputField.value = event.target.innerHTML;
            UpdateParams();
        }
    }, 100);
};
if (citySuggestion) {
    citySuggestion.addEventListener("click", changeCityValue);
}

// =================================== //
// Create logic for aldeady seen jobs  //
// =================================== //

const updateLsOnClick = () => {
    let jobs = document.querySelectorAll(".bricks-cards-job-offer");
    if (jobs) {
        jobs.forEach((job) => {
            let joblink = job.querySelector(".single-job-link");
            let jobbtn = job.querySelector(".jobs-listing-job-button");
            if (joblink) {
                jobbtn.addEventListener("click", (e) => {
                    let _id = job.id;
                    if (localStorage.getItem("seenJobs") === null) {
                        localStorage.setItem("seenJobs", "");
                    }
                    if (localStorage.getItem("seenJobs") == "") {
                        let seenJobs = _id;
                        localStorage.setItem(
                            "seenJobs",
                            JSON.stringify(seenJobs)
                        );
                    } else {
                        let seenJobs = JSON.parse(
                            localStorage.getItem("seenJobs")
                        );
                        if (typeof seenJobs == "string") {
                            seenJobs = seenJobs.split(",");
                        }
                        if (seenJobs.length > 0 && seenJobs != "") {
                            let flag = 0;
                            seenJobs.forEach((num) => {
                                if (num == _id) {
                                    flag = 1;
                                }
                            });
                            if (flag === 0) {
                                seenJobs.push(_id);
                            }
                            localStorage.setItem(
                                "seenJobs",
                                JSON.stringify(seenJobs)
                            );
                        }
                    }
                });
                joblink.addEventListener("click", (e) => {
                    let _id = job.id;
                    if (localStorage.getItem("seenJobs") === null) {
                        localStorage.setItem("seenJobs", "");
                    }
                    if (localStorage.getItem("seenJobs") == "") {
                        let seenJobs = _id;
                        localStorage.setItem(
                            "seenJobs",
                            JSON.stringify(seenJobs)
                        );
                    } else {
                        let seenJobs = JSON.parse(
                            localStorage.getItem("seenJobs")
                        );
                        if (typeof seenJobs == "string") {
                            seenJobs = seenJobs.split(",");
                        }
                        if (seenJobs.length > 0 && seenJobs != "") {
                            let flag = 0;
                            seenJobs.forEach((num) => {
                                if (num == _id) {
                                    flag = 1;
                                }
                            });
                            if (flag === 0) {
                                seenJobs.push(_id);
                            }
                            localStorage.setItem(
                                "seenJobs",
                                JSON.stringify(seenJobs)
                            );
                        }
                    }
                });
            }
        });
    }
};

const updateSeenJobs = () => {
    let jobsFromLs = JSON.parse(localStorage.getItem("seenJobs"));
    if (jobsFromLs === null || jobsFromLs == "") {
        return;
    } else {
        if (typeof jobsFromLs == "string") {
            let jobElement = document.getElementById(`${jobsFromLs}`);
            if (jobElement) {
                jobElement.classList.add("seen-job");
            }
        } else {
            jobsFromLs.forEach((job) => {
                let _id = job;
                let jobElement = document.getElementById(`${_id}`);
                if (jobElement) {
                    jobElement.classList.add("seen-job");
                }
            });
        }
    }
};

// Change color of button where is no filter applied
const resultBtnChangeColor = () => {
    const irsLine = document.querySelector(".irs--flat");
    if (ShowResultsButton && acitveFilterContainer) {
        if (acitveFilterContainer.children.length == 0) {
            ShowResultsButton.classList.add("job-search-button-grey");
            irsLine.classList.add("not-activated");
        } else {
            ShowResultsButton.classList.remove("job-search-button-grey");
            if (
                document
                    .querySelector(".active-filter-card")
                    .classList.contains("city")
            ) {
                irsLine.classList.remove("not-activated");
            }
        }
    }
};

if (document.querySelector(".active-filter-container")) {
    const irsLine = document.querySelector(".irs--flat");
    const parent = document.querySelector(".active-filter-container");
    parent.addEventListener("click", function (event) {
        const target = event.target;
        // check if clicked element is the one you want to target
        if (target.matches(".city") || target.matches(".city p")) {
            irsLine.classList.add("not-activated");
        }
    });
}

// irsLine.classList.add("not-activated");
window.addEventListener("load", updateLsOnClick);
window.addEventListener("load", updateSeenJobs);
window.addEventListener("load", resultBtnChangeColor);
window.addEventListener("load", () => {
    const irsLine = document.querySelector(".irs--flat");
    // if(document.querySelector('.active-filter-card').classList.contains('city')){
    //  irsLine.classList.remove('not-activated')
    //  } else {
    //     irsLine.classList.add('not-activated')
    //  }
});
window.addEventListener("load", () => {
    if (document.querySelector(".active-filter-card.title")) {
        const irsLine = document.querySelector(".irs--flat");
        irsLine.classList.add("not-activated");
    }
    if (document.querySelector(".active-filter-container")) {
        if (
            document.querySelector("#jobtitle").value !== "" &&
            document.querySelector("#city").value !== ""
        ) {
            document
                .querySelector(".irs.irs--flat")
                .classList.remove("not-activated");
        }
    }
});

// =================================== //
//         Email sub button logic      //
// =================================== //

window.addEventListener("load", () => {
    const emailButton = document.querySelector(".email-sub-btn");
    if (emailButton) {
        const emailCheckBox = emailButton.querySelector(
            'input[type="checkbox"]'
        );
        if (emailCheckBox) {
            emailButton.addEventListener("click", () => {
                if (emailCheckBox.checked) {
                    emailCheckBox.checked = false;
                } else {
                    emailCheckBox.checked = true;
                }
            });
        }
    }

    // Modal toggle button
    const closeBtn = document.querySelector(".close-btn");
    if (closeBtn) {
        closeBtn.addEventListener("click", () => {
            const emailButton = document.querySelector(".email-sub-btn");
            if (emailButton) {
                const emailCheckBox = emailButton.querySelector(
                    'input[type="checkbox"]'
                );
                if (emailCheckBox) {
                    emailCheckBox.checked = false;
                }
            }
        });
    }

    // Change color of button

    const filterArrowContainer = document.querySelectorAll(".toggle-item");
    filterArrowContainer.forEach((elem) => {
        const filterArrow = elem.querySelector(".down-arrow-icon");
        elem.addEventListener("click", () => {
            filterArrow.classList.toggle("arrow-rotated");
        });
    });
});
