$(document).ready(function () {
    if ($("#job-offer-detail").length !== 0) {
        //reduce min-height of h1 if there is no image
        if ($(".job-detail-image-container > img").length === 0) {
            $(".job-detail-describtion > h1").css("min-height", "unset");
        }
        //sticky apply button
        
        var applyButtonContainer = $(".job-offer-apply-container");
        var offsetTopButton = applyButtonContainer[0].offsetTop + 100;
        var offsetTopForm = $("#job-contact")[0].offsetTop + 100;

        window.onresize = function () {
            if (window.innerWidth < 992) {
                applyButtonContainer = $(".job-offer-apply-container");
                offsetTopButton = applyButtonContainer[0].offsetTop + 100;
                offsetTopForm = $("#job-contact")[0].offsetTop + 100;
                makeSticky();
            } else {
                applyButtonContainer.css("position", "relative");
                applyButtonContainer.css("min-width", "unset");
                $(".job-detail-describtion").css("margin-bottom", "0px");
            }
        };
        if(!document.querySelector('.new-experts-design') && !document.querySelector('.new-serwedo-design')) {
            console.log("mejkstiki")
            window.onscroll = function () {
                makeSticky();
            };
        }
        function makeSticky() {
            if (window.innerWidth < 992) {
                if (
                    offsetTopButton < window.pageYOffset &&
                    offsetTopForm > window.pageYOffset
                ) {
                    applyButtonContainer.css("position", "fixed");
                    applyButtonContainer.css("min-width", "100%");
                    $(".job-detail-describtion").css("margin-bottom", "111px");
                } else {
                    applyButtonContainer.css("position", "relative");
                    applyButtonContainer.css("min-width", "unset");
                    $(".job-detail-describtion").css("margin-bottom", "0px");
                }
            }
        }

        var jobId = $(".job-detail-id").text();

        //remember job in local storage
        $(".job-offer-remeber-link-not-saved").on("click", function (e) {
            e.preventDefault();
            localStorage.setItem(jobId, "markedJob");
            toggleRememberLink(true);
            updateJobsBooked(false);
        });
        //forget job on local storage
        $(".job-offer-remeber-link-saved").on("click", function (e) {
            e.preventDefault();
            localStorage.removeItem(jobId);
            toggleRememberLink(false);
            updateJobsBooked(false);
        });
        //check if job was remembered
        if (localStorage.getItem(jobId) === "markedJob") {
            toggleRememberLink(true);
        }
        //toggle remember-link state
        function toggleRememberLink(saved) {
            if (saved) {
                $(".job-offer-remeber-link-not-saved").addClass("d-none");
                $(".job-offer-remeber-link-saved").removeClass("d-none");
            } else {
                $(".job-offer-remeber-link-not-saved").removeClass("d-none");
                $(".job-offer-remeber-link-saved").addClass("d-none");
            }
        }
    }
    if ($(".jobs-search-body").length !== 0) {
        let ids = [];
        $(".bricks-cards-container").each(function (index) {
            ids.push(this.dataset.jobid);
        });
        ids.forEach(function (id) {
            if (localStorage.getItem(id) === null) {
                $(
                    'div[data-jobid="' +
                        id +
                        '"] .bricks-cards-remeber-link-not-saved'
                ).removeClass("d-none");
                $(
                    'div[data-jobid="' +
                        id +
                        '"] .bricks-cards-remeber-link-saved'
                ).addClass("d-none");
            }
        });

        $(".bricks-cards-remeber-link-saved").on("click", function (e) {
            e.preventDefault();
            var jobId = $(e.target).closest(".bricks-cards-container")[0]
                .dataset.jobid;
            localStorage.removeItem(jobId);
            toggleRememberLink(
                false,
                $(e.target).closest(".bricks-cards-container")
            );
            updateJobsBooked(false);
        });
        $(".bricks-cards-remeber-link-not-saved").on("click", function (e) {
            e.preventDefault();
            var jobId = $(e.target).closest(".bricks-cards-container")[0]
                .dataset.jobid;
            localStorage.setItem(jobId, "markedJob");
            toggleRememberLink(
                true,
                $(e.target).closest(".bricks-cards-container")
            );
            updateJobsBooked(false);
        });
        //check if job was remembered
        if (localStorage.getItem(jobId) === "markedJob") {
            toggleRememberLink(true);
        }
        //toggle remember-link state
        function toggleRememberLink(saved, containerElement) {
            if (saved) {
                containerElement
                    .find(".bricks-cards-remeber-link-not-saved")
                    .addClass("d-none");
                containerElement
                    .find(".bricks-cards-remeber-link-saved")
                    .removeClass("d-none");
            } else {
                containerElement
                    .find(".bricks-cards-remeber-link-not-saved")
                    .removeClass("d-none");
                containerElement
                    .find(".bricks-cards-remeber-link-saved")
                    .addClass("d-none");
            }
        }
    }
    var JobsBookedHref;
    function updateJobsBooked(initialLoading) {
        if (JobsBookedHref == undefined)
            JobsBookedHref = $("#jobs-booked").attr("href");
        var newLink = JobsBookedHref + "?booked=" + getAllMarkedJobsAsString();
        $("#jobs-booked").attr("href", newLink);
        $(".jobs-booked-amount").text(getAllMarkedJobs().length);

        if ($(".jobs-search-body-noFilter").length !== 0 && !initialLoading) {
            history.replaceState(history.state, "", newLink);
        }
    }
    updateJobsBooked(true);

    //check pimcore for deleted jobs
    var dateOfLastSync = 0;
    var bookedJobs;
    if (sessionStorage.getItem("LastSyncWithDatabase"))
        dateOfLastSync = sessionStorage.getItem("LastSyncWithDatabase");
    if (
        dateOfLastSync + 180000 < Date.now() ||
        $(".jobs-search-body-noFilter").length !== 0
    ) {
        bookedJobs = getAllMarkedJobs();
        checkIfJobDeleted(0);
    }
    function checkIfJobDeleted(n) {
        if (getAllMarkedJobs().length == n) {
            updateJobsBooked(true);
            sessionStorage.setItem("LastSyncWithDatabase", Date.now());
            return;
        } else {
            const htmlTag = document.documentElement;
            const langAttribute = htmlTag.getAttribute("lang");
            $.get(
                "https://" +
                    window.location.hostname +
                    `/${langAttribute}/check_job`,
                { id: bookedJobs[n] }
            ).done(function (data) {
                if (data.response == "failed") {
                    localStorage.removeItem(bookedJobs[n]);
                    console.warn(
                        "the job with the Id:" +
                            bookedJobs[n] +
                            "was removed from the local Storage"
                    );
                }
                checkIfJobDeleted(n + 1);
            });
        }
        getAllMarkedJobs();
    }
});
//get all marked Jobs from local Storage
function getAllMarkedJobs() {
    var allJobIds = [];
    for (var i = 0, len = localStorage.length; i < len; i++) {
        var key = localStorage.key(i);
        var value = localStorage[key];
        if (value === "markedJob") allJobIds.push(key);
    }
    return allJobIds;
}
function getAllMarkedJobsAsString() {
    return getAllMarkedJobs().toString();
}

$(document).ready(function () {
    if ($("#job-contact").length !== 0) {
        $(".job-contact-content").css(
            "min-height",
            $(".job-contact-formular").height() + 100 + "px"
        );
        $(window).resize(function () {
            $(".job-contact-content").css(
                "min-height",
                $(".job-contact-formular").height() + 100 + "px"
            );
        });
    }
});
// setOrangeBookmarkOnLoad = () => {
if (document.querySelector(".bricks-cards-container")) {
    const allJobsIds = document.querySelectorAll(".bricks-cards-container");
    allJobsIds.forEach((job) => {
        const dataId = job.getAttribute("data-jobid");
        if (localStorage.getItem(dataId)) {
            job.querySelector(
                ".bricks-cards-remeber-link-saved"
            ).classList.remove("d-none");
            job.querySelector(
                ".bricks-cards-remeber-link-not-saved"
            ).classList.add("d-none");
        }
    });
}
// }
