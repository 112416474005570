if(document.querySelector('.details-banner-video')){
    $('.details-banner-video').magnificPopup({
        type: 'video'
    });   
}
$(document).ready(function () {
    $('#datatable1').dataTable();

});
if (navigator.userAgent.indexOf("Edge") > -1 || navigator.userAgent.indexOf("Edg") > -1){
    $('body > #wrapper').addClass('Edge')
}
(function () {
    var list = $(".search-result-list");

    $(document).on("keyup", function (e) {
        if (e.keyCode === 40 || e.keyCode === 38) {
            e.preventDefault();
            var code = e.keyCode;
            var listItems = list.children("li");
            var currentSelected;

            if (list.hasClass("search-list-open") && listItems.length > 0 && $("body").hasClass("top-search-open")) {
                if (list.find("li.selected").length === 0) {
                    if (code === 40) {
                        currentSelected = listItems[0];
                    } else {
                        currentSelected = listItems[listItems.length - 1];
                    }

                    currentSelected.classList.add("selected");
                } else {
                    var current = list.find("li.selected");
                    current.removeClass("selected");
                    if (code === 40) {
                        currentSelected = current.next("li");
                        currentSelected.addClass("selected");
                    } else {
                        currentSelected = current.prev();
                        currentSelected.addClass("selected");
                    }

                }

            }
        }

        if (e.keyCode === 13) {
            if (list.hasClass("search-list-open") && $("body").hasClass("top-search-open")) {
                var selected = list.find("li.selected > a");

                if (selected[0].getAttribute("target") === "_blank") {
                    window.open(selected[0].href, "_blank");
                } else {
                    window.location = selected[0].href;
                }
            }
        }
    });
})();

//adjust featureboxes in height to match each other

$(document).ready(function () {
    adjustFeatureboxheight()
});
$(window).on('resize', function(event) {
    event.preventDefault();
    adjustFeatureboxheight()
});
function adjustFeatureboxheight() {
    $('.feature-box-container').each(function() {
        let maxHeight = 0;
        if( $(window).width() >= 768){
            $(this).find('.featured-boxes-wrapper-box-shadow').each(function () {
                if(maxHeight < $(this).outerHeight() ) maxHeight = $(this).outerHeight();
            })
            maxHeight = maxHeight + 'px';
        }else{
            maxHeight = 'unset'
        }
        $(this).find('.featured-boxes-wrapper-box-shadow').each(function () {
            $(this).css('height', maxHeight);
        })
        
    })
}
// Navigation menu icons aligment

if(document.querySelector('#primary-menu-trigger')){
    let navBtn = document.querySelector('#primary-menu-trigger')
    let navIcons = document.querySelector('#primary-menu .border-top-orange')
    navBtn.addEventListener('click', () => {
        if(!navIcons.classList.contains('d-block')){
            navIcons.setAttribute('style', 'display:flex !important; touch-action: pan-y');
        } else {
            navIcons.setAttribute('style',  'touch-action: pan-y');
        }
        
    })
}