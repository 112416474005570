if (document.querySelector(".form-builder-wrapper")) {
    const allCheckBoxes = document.querySelectorAll(".checkbox-wrap");
    allCheckBoxes.forEach((elem) => {
        const checkBoxparent = elem.parentElement.parentElement;
        checkBoxparent.classList.add("wrapAll");
    });
    $(".wrapAll").wrapAll("<div class='checkbox-grid' />");

    const makeFormTitle = document.querySelectorAll(".form-title");
    makeFormTitle.forEach((title) => {
        title.previousElementSibling.classList.add("form-heading");
    });
    const makeFormSubheading = document.querySelectorAll(".form-subheading");
    makeFormSubheading.forEach((title) => {
        title.previousElementSibling.classList.add("form-subheadline");
    });
    if (document.querySelector(".form-textarea")) {
        const makeFormTextAreaTitle =
            document.querySelectorAll(".form-textarea");
        makeFormTextAreaTitle.forEach((title) => {
            if (title.previousElementSibling) {
                title.previousElementSibling.classList.add("textarea-title");
            }
        });
    }
    if (document.querySelector(".checkbox-custom")) {
        const makeCheckBoxFlex = document.querySelectorAll(".checkbox-custom");
        makeCheckBoxFlex.forEach((checkbox) => {
            checkbox.parentElement.classList.add("checkbox-flex");
        });
    }
    const textAreaMargin = document.querySelectorAll(".textarea-title");
    textAreaMargin.forEach((elem) => {
        elem.parentElement.classList.add("textarea-margin");
    });
    const verticalCheckbox = document.querySelectorAll(".checkbox-vertical");
    verticalCheckbox.forEach((elem) => {
        elem.parentElement.classList.add("vertical-checkbox-wrap");
    });

    const accordionWrap = document.querySelectorAll(".accordion-wrap");
    accordionWrap.forEach((elem) => {
        elem.previousElementSibling.classList.add("accordion-chevron-down");
        elem.parentElement.parentElement.classList.add("accordion-container");
        const accordionLabel = elem.previousElementSibling;
        accordionLabel.classList.add("accordion-title");
        accordionLabel.addEventListener("click", () => {
            elem.classList.toggle("accordion-open");
            if (elem.classList.contains("accordion-open")) {
                elem.style.maxHeight = elem.scrollHeight + "px";
                elem.previousElementSibling.classList.remove(
                    "accordion-chevron-down"
                );
                elem.previousElementSibling.classList.add(
                    "accordion-chevron-up"
                );
            } else {
                elem.style.maxHeight = "0px";
                elem.previousElementSibling.classList.remove(
                    "accordion-chevron-up"
                );
                elem.previousElementSibling.classList.add(
                    "accordion-chevron-down"
                );
            }
        });
    });
    const submitFormBtn = document.querySelectorAll(".submit-form-btn");
    submitFormBtn.forEach((btn) => {
        btn.parentElement.classList.add("d-flex", "justify-content-center");
    });
    $(".accordion-container").wrapAll("<div class='form-accordion' />");
    if (document.querySelector(".help-block")) {
        document.querySelector(".help-block").parentElement.style.position =
            "relative";
    }
    console.log("firsssssst");
    if (document.querySelector(".dynamic-multi-file")) {
        const dynamicUploadDiv = document.querySelector(".dynamic-multi-file");
        const inputFile = document.createElement("input");
        inputFile.type = "file";
        console.log("123123");
        dynamicUploadDiv.appendChild(inputFile);
    }
}
