onload = () => {
    const sliderOuter = document.querySelector(".news-slider-outer");
    if (!sliderOuter) return;
    const sliderNav = sliderOuter.querySelector(".owl-nav");
    const sliderContainer = sliderOuter.querySelector(".owl-stage");
    if (!sliderNav || !sliderContainer) return;
    sliderNav.childNodes.forEach((singleChevron) => {
        singleChevron.addEventListener("click", () => {
            const activeSlides = sliderContainer.querySelectorAll(".active");
            if (!activeSlides) return;
            activeSlides[0].style.marginBottom = `10px`;
            activeSlides[1].style.marginBottom = `-10px`;
            activeSlides[2].style.marginBottom = `-20px`;
        });
    });
};
