
    $('document').ready(function(){
        $('.image-carousel-section .image-carousel').each(function(index) {
            adjustArrows(this);
            var owl = $(this);
            owl.on('resized.owl.carousel', function(event){adjustArrows(this)});
        })
    
        function adjustArrows(that) {
            $(that).find('.owl-next, .owl-prev').each(function(index){
                $(this).css('top', $(that).find('.owl-item').css('width').slice(0, -2) / 2);
            })
        }
    });
