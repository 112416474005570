const changeInputFileLabels = () => {
    const allInputTypeFile = document.querySelectorAll('input[type="file"]');
    const alldeleteUpload = document.querySelectorAll(
        ".contact-upload-delete-file"
    );
    var uploadText;
    if (allInputTypeFile) {
        allInputTypeFile.forEach((inputTypeFile) => {
            inputTypeFile.addEventListener("change", () => {
                let labelTypeFile = document.querySelector(
                    `.${inputTypeFile.id}`
                );
                if (!uploadText) uploadText = labelTypeFile.innerText;

                if (labelTypeFile && inputTypeFile.files[0].name) {
                    let replaceString = [];
                    for (let i = 0; i < inputTypeFile.files.length; i++) {
                        replaceString.push(inputTypeFile.files[i].name);
                    }
                    labelTypeFile.innerHTML = `<i class="av-icon av-icon-upload-files av-icon-size-32px"></i>${buildSpanWithFiles(
                        replaceString
                    )}`;
                    inputTypeFile.parentNode
                        .querySelector(".contact-upload-delete-file")
                        .classList.remove("d-none");

                    if ($("#job-contact").length !== 0) {
                        $(".job-contact-content").css(
                            "min-height",
                            $(".job-contact-formular").height() + 100 + "px"
                        );
                    }
                }
            });
        });
    }
    if (alldeleteUpload) {
        alldeleteUpload.forEach((deleteUpload) => {
            deleteUpload.addEventListener("click", (e) => {
                let inputTypeFile =
                    e.target.parentNode.parentNode.querySelector(
                        'input[type="file"]'
                    );
                let labelTypeFile = document.querySelector(
                    `.${inputTypeFile.id}`
                );
                if (labelTypeFile && inputTypeFile.files[0].name) {
                    inputTypeFile.value = "";
                    labelTypeFile.innerHTML = `<i class="av-icon av-icon-upload-files av-icon-size-32px"></i><span>${uploadText}</span>`;
                    e.target.classList.add("d-none");

                    if ($("#job-contact").length !== 0) {
                        $(".job-contact-content").css(
                            "min-height",
                            $(".job-contact-formular").height() + 100 + "px"
                        );
                    }
                }
            });
        });
    }

    function buildSpanWithFiles(files) {
        if (files.length == 1) {
            return `<span>${files.toString()}</span>`;
        } else {
            return `<span class="multiple-files scrollable">${files.toString()}</span>`;
        }
    }
};
changeInputFileLabels();
