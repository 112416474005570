if (document.querySelector(".filepond")) {
    const uploadLabelText = document.querySelector(".filepond-browse");
    let uploadLabelHTML = `
    <div class="d-flex justify-content-center flex-column align-items-center">
<i style="width:48px; height:48px;" class="icon av-icon av-icon-cloud-upload"></i>
<span>.PDF .JPG .PNG .DOC</span>
${uploadLabelText.innerHTML}
</div>
`;
    if (document.querySelector(".filepond-fly-out")) {
        const pondFlyOut = FilePond.create(
            document.querySelector(".filepond-fly-out"),
            {
                labelIdle: `${uploadLabelText ? uploadLabelHTML : ""}`,
                styleButtonRemoveItemPosition: "right",
                iconRemove: `<i class="icon circled-x-icon "></i>`,
                credits: false,
                storeAsFile: true,
                maxTotalFileSize: "30MB",
                acceptedFileTypes: [
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                    "application/msword",
                ],
                fileValidateTypeLabelExpectedTypes: "",
            }
        );
        pondFlyOut.setOptions({
            maxFiles: 30,
        });
    }
    if (document.querySelector(".filepond-job-detail")) {
        const pondDetailPage = FilePond.create(
            document.querySelector(".filepond-job-detail"),
            {
                labelIdle: `${uploadLabelText ? uploadLabelHTML : ""}`,
                styleButtonRemoveItemPosition: "right",
                iconRemove: `<i class="icon circled-x-icon "></i>`,
                credits: false,
                storeAsFile: true,
                maxTotalFileSize: "10MB",
                acceptedFileTypes: [
                    "image/jpg",
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                    "application/msword",
                ],
                fileValidateTypeLabelExpectedTypes: "",
            }
        );
        pondDetailPage.setOptions({
            maxFiles: 30,
        });
        document.addEventListener("FilePond:updatefiles", (e) => {
            if (window.matchMedia("(min-width: 991px)").matches) {
                document.querySelector(".job-contact").style.paddingBottom = `${
                    e.detail.items.length * 2 + 20
                }%`;
            } else {
                document.querySelector(
                    ".job-contact"
                ).style.paddingBottom = `20%`;
            }
        });
    }
}
