const progressBar = () => {
    const setProgressWidthAnimation = () => {
        const allProgressBars = document.querySelectorAll(".progress-bar");
        allProgressBars.forEach((bar) => {
            let progressValue = bar.getAttribute("aria-valuenow");
            progressValue = Number(progressValue);
            let progressValueMax = bar.getAttribute("aria-valuemax");
            progressValueMax = Number(progressValueMax);
            bar.style.width = `${(progressValue / progressValueMax) * 100}%`;
            bar.parentElement.previousElementSibling.style.paddingLeft = `${
                (progressValue / progressValueMax) * 100 - 1
            }%`;
        });
    };
    const setProgressZero = () => {
        const allProgressBars = document.querySelectorAll(".progress-bar");
        allProgressBars.forEach((bar) => {
            bar.style.width = "0%";
            bar.parentElement.previousElementSibling.style.paddingLeft = "0%";
        });
    };
    const progressBarTarget = document.querySelector(".progress");

    const progressObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                console.log("Sjecem po sredini");
                setProgressWidthAnimation();
            } else {
                console.log("ne sjecem");
                setProgressZero();
            }
        });
    });
    if (progressBarTarget) {
        progressObserver.observe(progressBarTarget);
    }
};
if (!document.querySelector("#loader")) {
    progressBar();
}
document.onreadystatechange = function () {
    var state = document.readyState;
    if (state == "interactive") {
        if (document.getElementById("wrapper")) {
            document.getElementById("wrapper").style.visibility = "hidden";
        }
    } else if (state == "complete") {
        setTimeout(function () {
            if (document.getElementById("loader")) {
                document.getElementById("interactive");
                document.getElementById("loader").style.visibility = "hidden";
                if (document.getElementById("wrapper")) {
                    document.getElementById("wrapper").style.visibility =
                        "visible";
                }
                progressBar();
            }
        }, 1000);
    }
};

window.addEventListener("load", () => {
    if (document.querySelector(".message-success")) {
        const formHeader = document.querySelector(".detail-page-header");
        const alertMessage = document.querySelector(".message-success");
        alertMessage.scrollIntoView();
        formHeader.style.display = "none";
    }
});

let allBookmarked = document.querySelectorAll(".bricks-cards-job-offer");
let bookedAmount = document.querySelector(".jobs-booked-amount");
if (bookedAmount) {
    allBookmarked = allBookmarked.length;
    bookedAmount.innerText = `${allBookmarked}`;
}
// ============================================= //
// Reduce margin on first section under the Hero //
// ============================================= //
window.addEventListener("load", (event) => {
    const heroSlider = document.querySelector(".jobs-search-header"); // check is there a Hero header
    const detailPageHeader = document.querySelector(".detail-page-header");
    if (heroSlider || detailPageHeader) {
        const firstElement = document.querySelector(".section-spacing");
        // firstElement.classList.remove("section-spacing");
        //firstElement.style.marginTop = "30px";
    }
});

// ============================================ //
//          Table coulmn functionality          //
// ============================================ //
window.addEventListener("load", () => {
    const tables = document.querySelectorAll(".table-custom");
    const boxShadows = document.querySelectorAll(".box-shadow");
    const tableContainers = document.querySelectorAll(".table-container-width");
    if (tables && boxShadows && tableContainers) {
        tables.forEach((table, index) => {
            // Column width with responsivnes part
            const tableHeader = table.querySelector("thead").firstElementChild;
            let displayedColumns = 100 / 4;
            let containerWidth = displayedColumns * tableHeader.children.length;
            table.style.width = `${containerWidth}%`;
            let columnWidth = 100 / tableHeader.children.length;
            let theadChildren = Array.from(tableHeader.children);
            theadChildren.forEach((element) => {
                element.style.width = `${columnWidth}%`;
            });
            // table body height for box-shadow
            const tableBody = table.querySelector("tbody");
            boxShadows[index].style.height = `${tableBody.clientHeight}px`;
            // Remove shadow at the end of scroll
            tableContainers[index].addEventListener("scroll", () => {
                let container = tableContainers[index];

                if (
                    container.offsetWidth + container.scrollLeft >=
                    container.scrollWidth
                ) {
                    boxShadows[index].style.display = "none";
                } else {
                    boxShadows[index].style.display = "block";
                }
            });
            // Set height of boxshadow on every resize
            window.addEventListener("resize", () => {
                boxShadows[index].style.height = `${tableBody.clientHeight}px`;
            });
        });
    }
});

// ============================================ //
//               Hero Slider fix                //
// ============================================ //
window.addEventListener("load", () => {
    let flag = false;
    const body = document.querySelector("body");
    if (
        body.classList.contains("expert") ||
        body.classList.contains("industriservice")
    ) {
        flag = true;
    }

    if (flag) {
        const headerContainer = document.querySelector(".jobs-search-header");
        if (headerContainer) {
            const imageContainer = headerContainer.querySelector(".tns-inner");
            if (imageContainer) {
                imageContainer.style.height = `${headerContainer.clientHeight}px`;
                window.addEventListener("resize", () => {
                    imageContainer.style.height = `${headerContainer.clientHeight}px`;
                });
            }
        }
    }
});
// ============================================ //
//              Search suggestion               //
//                   Desktop                    //
// ============================================ //
window.addEventListener("load", () => {
    if (document.querySelector(".user-nav-search")) {
        const autoSuggestionUl = document.querySelector(".ui-autocomplete");

        let position = document
            .querySelector(".user-nav-search")
            .getBoundingClientRect().left;
        if (window.innerWidth > 1200) {
            if (document.querySelector(".ui-autocomplete")) {
                autoSuggestionUl.style.left = `${position}px`;
                autoSuggestionUl.style.top = `69px`;
            }
        }
        window.addEventListener("resize", () => {
            let positionUl = document
                .querySelector(".user-nav-search")
                .getBoundingClientRect().left;
            if (window.innerWidth > 1200) {
                if (document.querySelector(".ui-autocomplete")) {
                    autoSuggestionUl.style.left = `${positionUl - 10}px`;
                    autoSuggestionUl.style.top = `69px`;
                }
            }
        });
    }
});
// ============================================ //
//              Custom box shadow               //
// ============================================ //
window.addEventListener("load", () => {
    const hamburgerIcon = document.querySelector("#primary-menu-trigger");

    if (hamburgerIcon) {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 1200) {
                const boxShadow = document.querySelector(".nav-box-shadow");
                if (boxShadow) {
                    boxShadow.style.display = "none";
                }
            }
        });
        hamburgerIcon.addEventListener("click", () => {
            if (window.innerWidth < 1200) {
                if (
                    document
                        .querySelector("body")
                        .classList.contains("primary-menu-open")
                ) {
                    const boxShadow = document.querySelector(".nav-box-shadow");
                    if (boxShadow) {
                        boxShadow.style.display = "block";
                    }
                } else {
                    const boxShadow = document.querySelector(".nav-box-shadow");
                    if (boxShadow) {
                        boxShadow.style.display = "none";
                    }
                }
            }
        });
    }
});

// ============================================ //
//          Owl Slider correct arrows           //
// ============================================ //
setTimeout(() => {
    if (document.querySelector(".owl-stage")) {
        const nextArrow = document.querySelectorAll(".owl-next");
        const prevArrow = document.querySelectorAll(".owl-prev");
        nextArrow.forEach((arrow) => {
            let backgroundColor =
                arrow.parentElement.parentElement.parentElement.parentElement
                    .parentElement.parentElement;
            if (backgroundColor.classList.contains("background-gray")) {
                arrow.style.background = "#b3b3b3";
            } else if (
                backgroundColor.classList.contains("background-dark-gray")
            ) {
                arrow.style.background = "#4c4c4c";
            }
            arrow.innerHTML = `<i class="icon-angle-right"></i>`;
        });
        prevArrow.forEach((arrow) => {
            let backgroundColor =
                arrow.parentElement.parentElement.parentElement.parentElement
                    .parentElement.parentElement;
            if (backgroundColor.classList.contains("background-gray")) {
                arrow.style.background = "#b3b3b3";
            } else if (
                backgroundColor.classList.contains("background-dark-gray")
            ) {
                arrow.style.background = "#4c4c4c";
            }
            arrow.innerHTML = `<i class="icon-angle-left"></i>`;
        });
    }
}, 1000);

// ============================================ //
//              Bookmark logo fix               //
// ============================================ //
if (!document.querySelector(".new-experts-design") || !document.querySelector(".new-serwedo-design")) {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    // Check if the media query is true
    if (document.querySelector(".job-grid-container")) {
        if (mediaQuery.matches) {
            const grid = document.querySelectorAll(".job-grid-container");
            grid.forEach((elem) => {
                if (elem.querySelector(".logo-grid")) {
                    if (elem.querySelector(".logo-grid").children.length == 0) {
                        elem.style.gridTemplateAreas =
                            '"Text Text Text logo bookmark" "Text Text Text logo baner"';
                    }
                }
            });
        } else {
            const grid = document.querySelectorAll(".job-grid-container");
            grid.forEach((elem) => {
                if (!elem.querySelector(".logo-grid")) {
                    elem.classList.remove(".job-grid-container");
                    elem.classList.add("bookmark-grid-container");
                    console.log("nema logo");
                } else {
                    const newJobBanner = elem.querySelector(
                        ".bricks-cards-new-job"
                    );
                    const jobName = elem.querySelector(".single-job-link h2");
                    console.log(newJobBanner);
                    console.log(jobName);
                    if (elem.querySelector(".logo-grid").children.length == 0) {
                        jobName.style.marginTop = "-35px";
                    }
                }
            });
        }
    }
}

// ============================================ //
//              Sticky Filter fix               //
// ============================================ //
if (document.querySelector(".filter-container-sticky")) {
    let contentOverflow = document.querySelector("#content");
    if (contentOverflow) {
        contentOverflow.style.overflow = "unset";
    }
}
// let elemtodelete = document.querySelector(`#_category option[value=""]`)
const employeeCheckbox = document.querySelector("#contact_form_myInterest_1");
const employerCheckbox = document.querySelector("#contact_form_myInterest_0");
const termsOfUse = document.querySelector("#contact_form_termsOfUse");
if (employeeCheckbox || employerCheckbox) {
    employeeCheckbox.addEventListener("click", () => {
        termsOfUse.parentElement.style.display = "none";
    });
    employerCheckbox.addEventListener("click", () => {
        termsOfUse.parentElement.style.display = "flex";
    });
}

// Open the Modal
function openModal() {
    document.getElementById("myModal").style.display = "block";
}

// Close the Modal
function closeModal() {
    document.getElementById("myModal").style.display = "none";
}

var slideIndex = 1;
showSlides(slideIndex);

// Next/previous controls
function plusSlides(n) {
    showSlides((slideIndex += n));
}

function showSlides(n) {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    console.log(slides);
    let dots = document.getElementsByClassName("demo");

    if (n > slides.length) {
        slideIndex = 1;
    }
    if (n < 1) {
        slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }

    if (slides.length > 0) {
        slides[slideIndex - 1].style.display = "block";
    }
}
window.addEventListener("load", function () {
    const currentURL = window.location.href;
    if (currentURL.includes("/jobs/booked")) {
        const hrefToUse = document
            .getElementById("jobs-booked")
            .getAttribute("href");
        const urlToUse = new URLSearchParams(hrefToUse.split("?")[1]);
        const bookedParams = urlToUse.getAll("booked");

        const languageLinks = document.querySelectorAll(
            '.language-switcher-box a[href*="/jobs/booked"]'
        );
        languageLinks.forEach((link) => {
            const linkURL = new URL(
                link.getAttribute("href"),
                window.location.href
            );
            linkURL.searchParams.set("booked", bookedParams);
            link.setAttribute("href", linkURL.href);
        });
    }
});

// new experts design hide job banner
if (document.querySelector(".new-experts-design ") || document.querySelector(".new-serwedo-design")) {
    const allJobBanners = document.querySelectorAll(".new-job-banner");
    allJobBanners.forEach((banner) => {
        const newJobBannerVisibility = banner.querySelector(
            ".bricks-cards-new-job"
        );
        if (newJobBannerVisibility.style.visibility === "hidden") {
            banner.style.display = "none";
        }
    });
    if (document.querySelector(".job-detail-new-job")) {
        const jobDetailNewJob = document.querySelector(".job-detail-new-job");
        if (jobDetailNewJob.style.visibility === "hidden") {
            jobDetailNewJob.style.display = "none";
        }
    }
}

// Delete seen jobs after 7 days
// if (document.querySelector(".jobs-headers")) {
//     if (
//         !localStorage.getItem("timestamp") &&
//         localStorage.getItem("seenJobs")
//     ) {
//         localStorage.setItem("timestamp", new Date().getTime());
//     }
//     if (localStorage.getItem("seenJobs")) {
//         var storedTimestamp = localStorage.getItem("timestamp");
//         var currentTime = new Date().getTime();
//         var sevenDaysInMillis = 50000;
//         // var sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000;

//         if (currentTime - storedTimestamp > sevenDaysInMillis) {
//             localStorage.removeItem("seenJobs");
//             localStorage.removeItem("timestamp");
//         }
//     }
// }
